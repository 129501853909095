export default {
  data() {
    return {
      form: {
        collectCompanyId: null,
        collectFactoryList: [
          {
            name: "",
            addressInfo: {
              zipCode: "",
              prefecturesId: null,
              address: "",
              buildingName: "",
            },
            tel: "",
            fax: "",
            jwnetContractNo: "",
            ediKey: "",
            publicConfirmationNo: "",
            collectOfficeList: null,
          },
        ]
      },
      duplicationNameCheckInfoList: [],
    };
  },
};
