import { PROHIBITED_CHARACTERS_REGEX } from "@/const";
export default {
  data() {
    return {
      csvErrorMsgs: []
    };
  },
  methods: {
    fileChange(e, index) {
      if (!e.target.files[0]) return;

      const file = e.target.files[0] || e.dataTransfer.files[0];
      if (!file.type.match("text/csv")) {
        alert("CSVファイルを選択してください");
        return;
      } else {
        this.csvFileName = file.name;
      }

      const reader = new FileReader();
      reader.readAsText(file);
      const loadFunc = () => {
        // エラーメッセージの初期化
        this.csvErrorMsgs = [];
        this.form.collectFactoryList[index].collectOfficeList = [];
        const tmpList = [];
        const lines = reader.result.split(/\r\n|\n/);
        lines.forEach((element, i) => {
          // フォーマットチェック
          const officeData = element.split(",");
          if (officeData.length !== 7) {
            const msg = `${i + 1}行目のフォーマットに誤りがあります。以下のフォーマットで作成して下さい。\n`
              + "営業所名,郵便番号,都道府県,市区町村番地,建物名号室,電話番号,FAX番号";
            this.csvErrorMsgs.push(msg);
            return;
          }

          // バリデーションチェック
          const data = {
            name: officeData[0],
            zipCode: officeData[1],
            prefecturesName: officeData[2],
            address: officeData[3],
            buildingName: officeData[4],
            tel: officeData[5],
            fax: officeData[6],
          };
          if (this.validateCSVData(data, i)) {
            tmpList.push(data);
          }
        });

        // フォーム用データに加工
        if (this.csvErrorMsgs.length === 0) {
          const formData = tmpList.map(office => {
            return {
              name: office.name,
              addressInfo: {
                zipCode: office.zipCode,
                prefecturesId: this.prefectures.filter(p => p.name === office.prefecturesName)[0].id,
                address: office.address,
                buildingName: office.buildingName,
              },
              tel: office.tel ? office.tel : null,
              fax: office.fax ? office.fax : null,
            }
          });
          this.form.collectFactoryList[index].collectOfficeList = formData;
        }
      };
      reader.onload = loadFunc;
    },

    validateCSVData(data, index) {
      // 事業者名の禁則文字のチェック
      if (PROHIBITED_CHARACTERS_REGEX.test(data.name)) {
        this.csvErrorMsgs.push(`${index + 1}行目エラー : 登録できない文字が含まれています。\n`);
      }
      // 営業所名
      if (!data.name || !this.validateMaxLen(data.name, 100)) {
        this.csvErrorMsgs.push(`${index + 1}行目エラー : 営業所名は100文字以内で入力してください。\n`);
      }
      // 郵便番号
      if (!this.validateNumberEqualLen(data.zipCode, 7)) {
        this.csvErrorMsgs.push(`${index + 1}行目エラー : 郵便番号は7桁の半角数字(ハイフンなし)で入力してください。\n`);
      }
      // 都道府県
      if (this.prefectures.filter(p => p.name === data.prefecturesName).length !== 1) {
        this.csvErrorMsgs.push(`${index + 1}行目エラー : 都道府県に誤りがあります。( ${data.prefecturesName} )\n`);
      }
      // 市区町村番地
      if (!data.address || !this.validateMaxLen(data.address, 100)) {
        this.csvErrorMsgs.push(`${index + 1}行目エラー : 市区町村番地は100文字以内で入力してください。\n`);
      }
      // 建物名号室
      if (!!data.buildingName && !this.validateMaxLen(data.buildingName, 100)) {
        this.csvErrorMsgs.push(`${index + 1}行目エラー : 建物名号室は100文字以内で入力してください。\n`);
      }
      // 電話番号
      if (!data.tel || !this.validateNumberMaxLen(data.tel, 15)) {
        this.csvErrorMsgs.push(`${index + 1}行目エラー : 電話番号は15桁以内の半角数値(ハイフンなし)で入力してください。\n`);
      }
      // FAX番号
      if (!!data.fax && !this.validateNumberMaxLen(data.fax, 15)) {
        this.csvErrorMsgs.push(`${index + 1}行目エラー : FAX番号は15桁以内の半角数値(ハイフンなし)で入力してください。( ${data.fax} ${data.fax ? data.fax.length : 0} )\n`);
      }
      return this.csvErrorMsgs.length === 0;
    }
  },
};
